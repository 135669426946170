export default class SelectInput {
	#containerNode;
	#callback;
	#inputNode;
	#selectNodes;
	#ariaName;
  #optionSelector;
	
	constructor(container, module) {

		this.#callback = module;

		this.#ariaName = "aria-expanded";

		this.#containerNode = document.querySelector(container);
    
		this.#inputNode = this.#containerNode.querySelector("[data-select-input]");
		this.#selectNodes = this.#containerNode.querySelector("[data-option-list]");
    this.#optionSelector = "data-option";

		if(!this.#containerNode) return;

		this.#inputNode.addEventListener("click", this._handleClick.bind(this));
    this.#selectNodes.addEventListener("click", this._changeValue.bind(this));

		window.addEventListener("click", e => {
			const clicked = e.target.closest(`${container}`);

			if (!clicked) this._reset();
		});
	}

	_handleClick(e) {
		this.#inputNode.classList.toggle("active");
    this.#selectNodes.classList.toggle("active");
    
    const isActive = this.#inputNode.classList.contains("active");
    
    if(isActive) {
      this.#inputNode.setAttribute(this.#ariaName, true);
    } else {
      this.#inputNode.setAttribute(this.#ariaName, false);
    }
	}

  _changeValue(e) {
    const clicked = e.target.closest(`[${this.#optionSelector}]`);
    
		// Guard clause
		if (!clicked) return;

		this.#callback(this.#inputNode, this.#selectNodes, clicked, this.#optionSelector, this._reset.bind(this));
  }

	_toDatasetProp(str) {
		return str.slice(5).replace(/-./g, x=>x[1].toUpperCase());
	}

  _reset() {
    this.#inputNode.classList.remove("active");
    this.#inputNode.setAttribute(this.#ariaName, false);
    this.#selectNodes.classList.remove("active");
  }
}