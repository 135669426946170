export function selectOne(inputNode, selectNodes, selected, optionSelector, callback) {

  for (const child of selectNodes.children) {
    child.classList.remove("active")
  }

  inputNode.value = selected.dataset[this._toDatasetProp(`${optionSelector}`)]
  selected.classList.add("active");
  
  callback();
};